<template>
  <div id="container">
    <Navbar color="white" @lang="getLang" />
    <div id="content-wrapper">
        <CampaignCase :lang="lang" type="CPCV" timeframe="6 Months" logo="la-ice/Image 3-min.png" banner="la-ice/Group 379-min-min.png"/>
    </div>
    <DetailCampaign :lang="lang" contentdetail="la-ice" :text="lang === 'id' ? textDetail : textDetailEn"/>
    <CampaignDemo :text="lang === 'id' ? textDemo : textDemoEn" :demo="demo"/>
    <CampaignEx :lang="lang" :ex="campaignEx"/>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import CampaignCase from '@/components/CaseStudy/CampaignCase'
import DetailCampaign from '@/components/CaseStudy/DetailCampaign'
import CampaignDemo from '@/components/CaseStudy/CampaignDemo'
import CampaignEx from '@/components/CaseStudy/CampaignEx'
export default {
  components: {
    Navbar,
    Footer,
    CampaignCase,
    DetailCampaign,
    CampaignDemo,
    CampaignEx
  },
  data () {
    return {
      lang: '',
      textDetail: 'Menargetkan campaign ditonton oleh audien yang memiliki usia 18-34 tahun, campaign LA ICE ditayangkan di beberapa lokasi yang sering dikunjungi oleh audien dengan rentang usia tersebut',
      textDetailEn: "The LA ICE campaign is targeted to Audience with age group 18-34 years old. That's why we broadcast it to several locations that are frequently visited by the target audience",
      textDemo: 'Perjalanan iklan dimulai saat audien melihat banner, kemudian berlanjur ke video, dan berakhir ke website',
      textDemoEn: 'The journey of advertising starts when the audience sees the banner, then moves on to the video, and ends on the website',
      demo: {
        first: {
          image: 'la-ice/1-min-min.png',
          text: 'Banner'
        },
        second: {
          image: 'la-ice/2-min-min.png',
          text: 'Video'
        },
        third: {
          image: 'la-ice/3-min-min.png',
          text: 'Website'
        }
      },
      campaignEx: {
        text: 'Untuk meningkatkan brand awareness produk LA ICE, setiap minggunya audien akan terpapar iklan maksimal sebanyak 4 kali. Durasi video yang ditayangkan selama 15 detik tanpa bisa di skip',
        textEn: 'To increase brand awareness of LA ICE products, every week the audience will be exposed to ads a maximum of 4 times. The duration of the video is 15 seconds without being able to skip',
        image: 'la-ice/la-mask-min.jpg'
      }
    }
  },
  methods: {
    getLang (value) {
      this.lang = value
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 550)
    }
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  min-height: calc(100vh - 3rem);
  overflow-x: hidden;
}

#content-wrapper {
  padding-top: 3rem;
  position: relative;
  height: auto;
  /* min-height: calc(100vh - 3rem);
  max-height: 100vh; */
  width: 100%;
}
</style>
